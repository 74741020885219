<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="group">
    <div v-for="item in propValue" :key="item.id">
      <component
        :is="item.component"
        :id="'component' + item.id"
        :key="item.id"
        class="component"
        :style="item.groupStyle"
        :prop-value="item.propValue"
        :element="item"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propValue: {
      type: Array,
      default: () => [],
    },
    element: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.group {
  & > div {
    //合并时的样式问题,暂时注释掉
    // position: relative;
    // width: 100%;
    // height: 100%;

    .component {
      position: absolute;
    }
  }
}
</style>
